<template>
    <div class="agent-profile">
        <div class="agent-id">
            {{agent.id}}
        </div>
        <div class="agent-image">
            <img v-if="agent.image" :src="agent.image">
            <img v-else src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fdefault_user.jfif_375x375?alt=media&token=865dae32-7d5e-44d0-ab32-3a3141e48d89">
        </div>
        <div class="upload-image">
            <input type="file" name="" id="profile-image" style="display:none;" @change="init_upload">
            <el-button v-if="agent.user" type="info" style="width:100%; max-width:150px;" @click="upload_profile_image">העלה תמונת פרופיל</el-button>
        </div>
        <div class="agent-title">
            <p>{{agent.name}}</p>
        </div>
        <div class="upload-file">
            <el-button v-if="!upload_pending" @click="handle_upload_file" type="primary" style="width:100%; max-width:150px;">
                העלאת קובץ<i class="el-icon-upload el-icon-right"></i>
            </el-button>
            <el-button disabled v-if="upload_pending" @click="handle_upload_file" type="primary" style="width:100%; max-width:150px;">
                אנא המתן...<i class="el-icon-upload el-icon-right"></i>
            </el-button>
        </div>
        <div v-if="files_data.length>0" class="agent-files">
            <table id="customers">
                <tr>
                    <th>מס</th>
                    <th>שם הקובץ</th>
                    <th>מעלה</th>
                    <th>כלים</th>
                </tr>
            <template v-for="(file,index) in files_data" :key="file.filePath">
                <tr>
                    <td>{{index+1}}</td>
                    <td>{{file.file_name}}</td>
                    <td>{{file.uploaded_name}}</td>
                    <td class="tools">
                        <i @click="handle_delete_file(file)" class="material-icons delete-icon">delete</i>
                        <a :href="file.url" target="_blank" ><i class="material-icons download-icon">file_download</i></a>
                    </td>
                </tr>
            </template>
            </table>
        </div>
         <input v-show="false" type="file" id="profile-file" @change="handle_change_upload_file">
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import {upload_image} from '../../Methods/Storage';
import {projectFirestore,projectFunctions,timestamp } from '../../firebase/config';
import { slide_pop_successs } from '../../Methods/Msgs';
import use_agents_storage from '../../Methods/use_agents_storage'
import { ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import {alert_confirm} from '../../Methods/Msgs'
import store from '../../store';


export default {
props: ["agent"],
emits:['finilize_profile'],
setup(props, {emit}){
    const {url,filePath,uploadImage,deleteImage} = use_agents_storage()
    const file = ref(null)
    const file_name = ref('')
    const files_data = ref([])
    const upload_pending = ref(false)

    const current_user = ref(computed(()=>{
        if(store.getters.user){
            return store.getters.user.display_name
        }
    }))
    const upload_profile_image = () => {
        const el = document.getElementById("profile-image");
        if(el){
            el.click();
        }
    }

    const init_upload = async (e) => {
        var file = e.target.files[0]
        const upload = await upload_image(props.agent.uid, file)
        if(upload == 'Type'){
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'אנא בחר קובץ תמונה מסוג תמונה (JPG, PNG, JPEG)'
            })
        }
        if(upload == 'Error'){
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'פנה לתמיכה טכנית.'
            })
        }
        if(upload.includes('https://fire')){
            await projectFunctions.httpsCallable('update_profile')({type: 'image', path: upload, uid: props.agent.uid});
            emit("finilize_profile");
            slide_pop_successs('תמונת הפרופיל עודכנה בהצלחה!')
        }
    }

    const handle_upload_file = async()=>{
        const { value: text } = await Swal.fire({
        input: 'text',
        inputLabel: 'כתוב את שם הקובץ',
        inputPlaceholder: 'שם הקובץ',
        inputAttributes: {
            'aria-label': 'Type your message here'
        },
        confirmButtonText: 'המשך',
        cancelButtonText: 'בטל',
        showCancelButton: true
        })
        if (text) {
            file_name.value = text
            document.getElementById('profile-file').click()
        }
    }

    const handle_change_upload_file = async(e)=>{
        const selected = e.target.files[0];
         if (selected){
             upload_pending.value = true
              file.value = selected;
              await uploadImage(file.value,props.agent.name,'files',file_name.value);
              files_data.value.push({
                  url:url.value,
                  filePath:filePath.value,
                  file_name:file_name.value,
                  uploaded_name:current_user.value,
              })
              await update_user_agent_files()
              upload_pending.value = false
         }
    }

    const update_user_agent_files = async()=>{
        await projectFunctions.httpsCallable('update_profile')({type: 'file', files: files_data.value, uid: props.agent.uid});
        
    }

    const handle_delete_file = (file)=>{
        alert_confirm('האם אתה בטוח שברצונך למחוק קובץ זה?')
        .then(async(res)=>{
            if(res.isConfirmed){
                const index = files_data.value.findIndex(_file=>_file.filePath==file.filePath)
                if(index!=-1){
                    await deleteImage(file.filePath)
                    files_data.value.splice(index,1)
                    await update_user_agent_files()
                }
            }
        })
    }

    const get_user_files_from_db=async()=>{
        const doc = await projectFirestore.collection('Users').doc(props.agent.uid).get()
        if(doc.data().uploaded_files){
            files_data.value = doc.data().uploaded_files
        }else{
            files_data.value=[]
        }
    }

    onMounted(async()=>{
       await get_user_files_from_db()
    })
    watch(props,async()=>{
        await get_user_files_from_db()
    })
    return{
       handle_delete_file,
       handle_upload_file,
       handle_change_upload_file,
       files_data,
       current_user,
       upload_pending,
       upload_profile_image,
       init_upload,
    }
}
}
</script>

<style scoped>
.agent-profile{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.agent-title{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: var(--yellow);
    margin-bottom: 10px;
}
.agent-image{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.agent-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.692);
}
.upload-file{
    padding-right:10px ;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.agent-id{
    position: absolute;
    top: 0px;
    right: 10px;
    width: 70px;
    height: 50px;
    background: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 26px;
    color: var(--light);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.699);
}
.upload-image{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.agent-files{
    margin-top: 5px;
    width: 100%;
    height: calc(100% - 250px);
    max-height: 200px;
    overflow:hidden;
    overflow-y: auto;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  text-align:center;
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr{background-color: #f2f2f2;}

#customers th {
  background-color: #635CD0;
  color: white;
}
i{
    cursor: pointer;
}
.delete-icon{
    color: crimson;
}
.download-icon{
    margin-right: 5px;
}
.download-icon:hover{
    color: darkorange;
}

</style>