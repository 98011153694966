<template>
    <div class="create-agent">
        <div class="loader" v-if="loading">
            <img src="https://www.globant.com/themes/custom/globant_corp_theme/images/2019/Loading-Globant.gif" alt="">
            <p>מייצר משתמש, אנא המתן...</p>
        </div>
        <div class="title">
            <p>יצירת משתמש סוכן</p>
        </div>
        <div class="agent-info">
            <p>שם הסוכן: {{agent.name}}</p>
            <p>פלאפון: {{agent.phone}}</p>
            <p>מס סוכן בפריורטי: {{agent.id}}</p>
        </div>
        <div class="form">
            <el-input placeholder="כתובת אימייל" v-model="new_user.email" style="margin-bottom: 15px;"></el-input>
            <el-input placeholder="סיסמה" v-model="new_user.password" style="margin-bottom: 15px;"></el-input>
            <el-input placeholder="חזור על הסיסמה" v-model="password2" style="margin-bottom: 15px;"></el-input>
            <el-button type="success" style="width:100%;" @click="register">צור משתמש</el-button>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFunctions } from '../../firebase/config';
import { slide_pop_successs } from '../../Methods/Msgs';
export default {
props: ['agent'],
emits:['finilize_user'],
setup(props,{emit}){
    const loading = ref(false);
    const password2 = ref('');

    const new_user = ref({
        name: props.agent.name,
        id: props.agent.id,
        phone: props.agent.phone,
        email: '',
        password: ''
    })

    const register = async () => {
        loading.value = true;
        const uid = await projectFunctions.httpsCallable('create_agent')({new_user: new_user.value})
        await projectFunctions.httpsCallable('create_profile')({uid: uid.data, name: new_user.value.name, company:'רוסמן לוגיסטיקה', 
            phone:new_user.value.phone, email: new_user.value.email, agent: new_user.value.id})
        slide_pop_successs('המשתמש נוצר בהצלחה!');
        loading.value = false;
        emit("finilize_user");
    }
    return{
        password2, new_user, register, loading
    }
}
}
</script>

<style>
.create-agent{
    width: 100%;
    height: 100%;
    position: relative;
}
.title{
    width: 100%;
    height: 100px;
    font-size: 40px;
    color: var(--yellow);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.agent-info{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: var(--light);
    padding-right: 10px;
}
.form{
    width: 100%;
    max-width: 375px;
    height: fit-content;
    margin: auto;
    margin-top: 25px;
}
.loader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.782);
    border-radius: 10px;
    transition: 0.25s;
    z-index: 10;
    flex-direction: column;
}
.loader p {
    font-size: 26px;
    color: var(--yellow);
    padding: 10px;
    border-radius: 5px;
}
.loader img{
    border-radius: 50%;
    width: 130px;
    margin-bottom: 25px;
    background: var(--light);
}
</style>