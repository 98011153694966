<template>
    <div class="agent-clients">
        <div class="fetching" v-if="fetching">
            <p>טוען נתונים...</p>
        </div>
        <div class="search-bar">
            <el-button type="warning" class="route-btn" @click="filter=null">כל הלקוחות</el-button>
            <el-button type="success" class="route-btn" @click="filter='A'">מסלול שבוע א</el-button>
            <el-button type="success" class="route-btn" @click="filter='B'">מסלול שבוע ב</el-button>
            <el-button type="success" class="route-btn" @click="filter='C'">מסלול שבוע ג</el-button>
            <el-button type="success" class="route-btn" @click="filter='D'">מסלול שבוע ד</el-button>
        </div>
        <div class="clients-table">
            <table id="table">
                <tr>
                    <th></th>
                    <th>מ.לקוח</th>
                    <th>שם</th>
                    <th>עיר</th>
                    <th>מגזר</th>
                    <th>ביקור</th>
                    <th>מגוון</th>
                    <th>מחירון</th>
                    <th>אובליגו</th>
                </tr>
                <template v-for="client in filtered_clients" :key="client.makat">
                    <tr>
                        <td style="text-align:center;"><i class="material-icons" style="cursor:pointer;">settings_applications</i></td>
                        <td>{{client.makat}}</td>
                        <td>{{client.name}}</td>
                        <td>{{client.city}}</td>
                        <td>{{client.migzar}}</td>
                        <td>{{client.visitation.DAYNAME}}</td>
                        <td>{{client.merakez}}</td>
                        <td>{{client.price_list_id}}</td>
                        <td>{{client.obligo}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="table-summery">

        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core';
import { projectFunctions } from '../../firebase/config';
export default {
props:["agent"],
setup(props){
    const fetching = ref(false);
    const filter = ref(null);

    const clients = ref([]);

    const filtered_clients = ref(computed(() => {
        if(!filter.value){
            return clients.value
        }
        let _clients = null;

        if(filter.value == 'A'){
            _clients = clients.value.filter(client => {
                return client.visitation.WEEK1 == 'Y'
            }).sort((aa, bb) => {
                if(aa.visitation.DAYNAME > bb.visitation.DAYNAME) {return 1}
                if(aa.visitation.DAYNAME < bb.visitation.DAYNAME) {return -1}
            })
        }   
        if(filter.value == 'B'){
            _clients = clients.value.filter(client => {
                return client.visitation.WEEK2 == 'Y'
            }).sort((aa, bb) => {
                if(aa.visitation.DAYNAME > bb.visitation.DAYNAME) {return 1}
                if(aa.visitation.DAYNAME < bb.visitation.DAYNAME) {return -1}
            })
        }   
        if(filter.value == 'C'){
            _clients = clients.value.filter(client => {
                return client.visitation.WEEK3 == 'Y'
            }).sort((aa, bb) => {
                if(aa.visitation.DAYNAME > bb.visitation.DAYNAME) {return 1}
                if(aa.visitation.DAYNAME < bb.visitation.DAYNAME) {return -1}
            })
        }   
        if(filter.value == 'D'){
            _clients = clients.value.filter(client => {
                return client.visitation.WEEK4 == 'Y'
            }).sort((aa, bb) => {
                if(aa.visitation.DAYNAME > bb.visitation.DAYNAME) {return 1}
                if(aa.visitation.DAYNAME < bb.visitation.DAYNAME) {return -1}
            })
        } 
        return _clients  
    }))


    watch(props.agent, async () => {
        fetching.value = true;
        const docs = await projectFunctions.httpsCallable('agents_clients')({agent: props.agent})
        clients.value = docs.data
        fetching.value = false;
    })

    onMounted(async () => {
        fetching.value = true;
        const docs = await projectFunctions.httpsCallable('agents_clients')({agent: props.agent})
        clients.value = docs.data
        fetching.value = false;
        console.log(clients.value);
    })

    return{
        clients, fetching, filtered_clients, filter
    }
}
}
</script>

<style scoped>
.agent-clients{
    position: relative;
    width: 100%;
    height: 100%;
}
.fetching{
    position: absolute;
    background: rgba(0, 0, 0, 0.616);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    flex-direction: column;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.774);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    z-index: 5;
}
.search-bar{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: var(--alert-purple);
    overflow-x: auto;
}
.clients-table{
    width: 100%;
    height: calc(100% - 50px - 40px);
    overflow-y: auto;
}
#table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.table-summery{
    width: 100%;
    height: 40px;
    background: var(--alert-purple);
}
.route-btn{
    margin: 0;
    margin-left: 5px;
    flex-shrink: 0;
}
</style>